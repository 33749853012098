// AzureUploader.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import exampleCardImg1 from './card-example-1.png';
import exampleCardImg2 from './card-example-2.png';
import './uploader.css';

const AzureUploader = props => {
  let currentUrlParams = new URLSearchParams(window.location.search);

  const part = props?.part;
  const card_info = props?.cardInfo;
  const [selectedFile, setSelectedFile] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [docUploaded, setDocUploaded] = useState(false);

  useEffect(() => {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const merchantIdParam = searchParams.get('merchant_id')?.split(',')[0];

    // Update the state with the extracted merchant_id
    setMerchantId(merchantIdParam);
  }, []);

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 string from data URL
        const fileType = file.type;
        resolve({ base64String, fileType });
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async () => {
    setIsUploading(true);

    const { base64String, fileType } = await fileToBase64(selectedFile);

    const fileTypeParts = fileType.split('/');
    const pdfName = fileTypeParts[1];
    let fileType_pdf = pdfName;
    let filetype = 'png';

    const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf']; // Add allowed file types here
    // Check if the selected file type is allowed
    if (!allowedFileTypes.includes(fileType)) {
      // Notify the user about the invalid file type
      alert('Invalid file type. Please upload only PNG, JPEG, or PDF files.');
      window.location.reload(); // Reload the page
      return;
    }

    if (fileType_pdf === 'pdf') {
      filetype = 'pdf';
    }
    // console.log(cardInfo?.cardInfo, cardInfo.length, pdfName);

    try {
      if (card_info && card_info?.length > 2) {
        console.log('//coming in 1st block', card_info);
        await axios
          .post(
            `https://api.superpe.in/backend/v2/merchant/merchant-bank-document/upload/${merchantId}`,
            {
              base64Image: base64String,
              cardNumber: card_info[0],
              bankName: card_info[1],
              network: card_info[2],
              card_id: card_info[3],
              updateTrue: true,
              type: "card",
              format: filetype
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )
          .then(response => {
            console.log('1st part of azureup running', response?.data); // Handle the API response
          })
          .catch(error => {
            console.log('error', error);
          });
      } else {
        // console.log('//coming in 2nd block', cardInfo);
        await axios
          .post(
            `https://api.superpe.in/backend/v2/merchant/merchant-bank-document/upload/${merchantId}`,
            {
              base64Image: base64String,
              format: filetype
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )
          .then(response => {
            console.log('2nd part of azureup running', response?.data); // Handle the API response
          })
          .catch(error => {
            console.log('error', error);
          });
      }

      setIsUploading(false); // Set uploading state to false
      setDocUploaded(true); // Set document uploaded state to true

      setTimeout(() => {
        // window.location.href =
        //   window.location.href + '&part=' + (Number(part) + 1); // Reload the page

        currentUrlParams.set('part', Number(part) + 1);

        window.location.href.includes('part')
          ? (window.location.href =
              window.location.pathname + '?' + currentUrlParams.toString())
          : (window.location.href =
              window.location.href + '&part=' + (Number(part) + 1));
      }, 2900);
    } catch (error) {
      console.error('Error:', error);
      setIsUploading(false); // Set uploading state to false in case of error
    }
  };

  return (
    <div className='flex flex-1 flex-col my-2'>
      {merchantId ? (
        <>
          <label className='block mb-2 text-lg font-medium text-gray-900 dark:text-white'>
            Upload card photo with
            <br />
            <span className='bg-blue-600 text-white px-1 text-2xl'>
              {part === 1 ? 'Last 4 digits ' : 'Name '}
              visible
            </span>
          </label>
          {part < 3 && (
            <figure className='w-full flex flex-col rounded-lg items-center justify-center my-2'>
              <img
                className='aspect-rect rounded-lg'
                src={part === 1 ? exampleCardImg1 : exampleCardImg2}
                alt='Card image example'
              />
              <figcaption className='mt-2 text-sm text-center text-gray-500 dark:text-gray-400'>
                Sample card photo
              </figcaption>
            </figure>
          )}
          <input
            className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
            id='file_input'
            type='file'
            onChange={handleFileChange}
          />
          {docUploaded && (
            <div
              id='toast-success'
              className='flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800'
              role='alert'
            >
              <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
                <svg
                  className='w-5 h-5'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                </svg>
                <span className='sr-only'>Check icon</span>
              </div>
              <div className='ms-3 text-sm font-normal'>
                Document uploaded successfully.
              </div>
              <button
                type='button'
                className='ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700'
                data-dismiss-target='#toast-success'
                aria-label='Close'
              >
                <span className='sr-only'>Close</span>
                <svg
                  className='w-3 h-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 14'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                  />
                </svg>
              </button>
            </div>
          )}
          {!docUploaded &&
            (isUploading ? (
              <button
                disabled
                type='button'
                className='py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center'
              >
                <svg
                  aria-hidden='true'
                  role='status'
                  className='inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='#1C64F2'
                  />
                </svg>
                Uploading...
              </button>
            ) : (
              <button
                type='button'
                className={`text-white ${
                  selectedFile
                    ? 'bg-blue-700 hover:bg-blue-800'
                    : 'bg-gray-300 hover:bg-gray-400'
                } focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-3.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 my-4`}
                onClick={handleUpload}
                disabled={selectedFile ? false : true}
              >
                {`Upload ${part === 1 ? 'last 4 digits ' : 'name '}`}
              </button>
            ))}
        </>
      ) : (
        <h5 className='mb-2 text-2xl font-bold tracking-tight text-red-700 text-left dark:text-white'>
          You're on an invalid link. <br />
          Please click on the link shared on your WhatsApp.
        </h5>
      )}
    </div>
  );
};

export default AzureUploader;
